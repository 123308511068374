import { useEffect } from "react";

const LAMAscii = () => {
  const ascii = ` 
This site was made with

██╗░░░░░░█████╗░██╗░░░██╗███████╗░░░░░░░░░░░███╗░░░███╗░█████╗░███╗░░██╗███████╗██╗░░░██╗
██║░░░░░██╔══██╗██║░░░██║██╔════╝░░░░██╗░░░░████╗░████║██╔══██╗████╗░██║██╔════╝╚██╗░██╔╝
██║░░░░░██║░░██║╚██╗░██╔╝█████╗░░░░██████╗░░██╔████╔██║██║░░██║██╔██╗██║█████╗░░░╚████╔╝░
██║░░░░░██║░░██║░╚████╔╝░██╔══╝░░░░╚═██╔═╝░░██║╚██╔╝██║██║░░██║██║╚████║██╔══╝░░░░╚██╔╝░░
███████╗╚█████╔╝░░╚██╔╝░░███████╗░░░░╚═╝░░░░██║░╚═╝░██║╚█████╔╝██║░╚███║███████╗░░░██║░░░
╚══════╝░╚════╝░░░░╚═╝░░░╚══════╝░░░░░░░░░░░╚═╝░░░░░╚═╝░╚════╝░╚═╝░░╚══╝╚══════╝░░░╚═╝░░░

https://www.loveandmoney.agency
`;

  useEffect(() => {
    const comment = document.createComment(`\n${ascii}\n`);
    document.body.insertBefore(comment, document.body.firstChild);
  }, [ascii]);

  return null;
};

export default LAMAscii;
